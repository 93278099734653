import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {MatButtonModule} from '@angular/material/button';
import { LoginComponent } from './login/login.component';
import { AuthFlowComponent } from './auth-flow/auth-flow.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../shared/shared.module';
import { UserMenuComponent } from './user-menu/user-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [
    LoginComponent,
    AuthFlowComponent,
    UserMenuComponent
  ],
  exports: [
    LoginComponent,
    AuthFlowComponent,
    UserMenuComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatButtonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    SharedModule,
    MatMenuModule,
    MatListModule
  ]
})
export class AuthModule {
}
