@if (sub$ | async) {
}

<mat-card>
  <mat-card-content>
    @if (loading$ | async) {
      <nbd-loader color="primary"></nbd-loader>
    } @else {
      <div class="grid grid-cols-1">
        <form [formGroup]="loginForm" class="w-full max-w-xs space-y-4">
          <img src="./assets/img/logo.png" alt="" class="py-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" autocomplete="username">
            @if (loginForm.controls.email.errors?.['required']) {
              <mat-error>
                Inserisci un'email
              </mat-error>
            }
            @if (loginForm.controls.email.errors?.['email']) {
              <mat-error>
                Indirizzo email non valido
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" autocomplete="current-password">
            <button mat-icon-button matSuffix
              (click)="togglePasswordVisibility()"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword"
              tabindex="-1">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            @if (loginForm.controls.password.errors?.['required']) {
              <mat-error>
                Inserisci una password
              </mat-error>
            }
          </mat-form-field>
          @if (error$ | async; as error) {
            @if (error.length > 0) {
              <div class="mat-bg-warn text-white text-center rounded p-2">
                {{error}}
              </div>
            }
          }
          <button mat-raised-button
            color="primary"
            class="w-full"
            (click)="login$.next()"
            [disabled]="loginForm.invalid">
            Login
          </button>
          <div class="flex justify-end w-full items-center">
            <!--            <mat-checkbox class="text-normal mat-caption" color="primary" formControlName="remember_me">Ricordami</mat-checkbox>-->
            <!--            <a routerLink="" class="mat-text-primary">Password dimenticata</a>-->
          </div>
        </form>
      </div>
    }
    <ng-template #login>
      <div class="grid grid-cols-1">
        <form [formGroup]="loginForm" class="w-full max-w-xs space-y-4">

          <img src="./assets/img/logo.png" alt="" class="py-4">

          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" autocomplete="username">
            @if (loginForm.controls.email.errors?.['required']) {
              <mat-error>
                Inserisci un'email
              </mat-error>
            }
            @if (loginForm.controls.email.errors?.['email']) {
              <mat-error>
                Indirizzo email non valido
              </mat-error>
            }
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" autocomplete="current-password">
            <button mat-icon-button matSuffix
              (click)="togglePasswordVisibility()"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword"
              tabindex="-1">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            @if (loginForm.controls.password.errors?.['required']) {
              <mat-error>
                Inserisci una password
              </mat-error>
            }
          </mat-form-field>
          @if (error$ | async; as error) {
            @if (error.length > 0) {
              <div class="mat-bg-warn text-white text-center rounded p-2">
                {{ error }}
              </div>
            }
          }

          @if (error$ | async; as error) {
            @if (error.length > 0) {
              <div class="mat-bg-warn text-white text-center rounded p-2">
                {{error}}
              </div>
            }
          }

          <button mat-raised-button
            color="primary"
            class="w-full"
            (click)="login$.next()"
            [disabled]="loginForm.invalid">
            Login
          </button>

          <div class="flex justify-end w-full items-center">

            <!--            <mat-checkbox class="text-normal mat-caption" color="primary" formControlName="remember_me">Ricordami</mat-checkbox>-->
            <!--            <a routerLink="" class="mat-text-primary">Password dimenticata</a>-->
          </div>
        </form>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
