import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthFlowComponent} from './auth-flow/auth-flow.component';

const routes: Routes = [
  {
    path: '', component: AuthFlowComponent, children: [
      {path: 'login', component: LoginComponent},
      {path: '', pathMatch: 'full', redirectTo: 'login'}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
