import { Component } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, catchError, first, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {AuthService} from '../auth.service';
import {debug} from '../../shared/rxjs/debug';
import {Router} from '@angular/router';

@Component({
  selector: 'nbd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loading$ = new BehaviorSubject<boolean>(false);
  login$ = new Subject<void>();
  error$ = new BehaviorSubject<string>('');

  sub$: Observable<any>;

  loginForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    remember_me: [true, Validators.required]
  });

  hidePassword = true;

  constructor(private auth: AuthService, private fb: FormBuilder, private router: Router) {
    this.sub$ = this.login$.pipe(
      tap(() => {
        this.loading$.next(true);
        this.error$.next('');
      }),
      switchMap(() => this.auth.login(this.loginForm.getRawValue()).pipe(
        debug('Login'),
        catchError((data) => {
          switch (data.error.description) {
            case 'userInactive':
              this.error$.next('Utente non attivo');
              break;
            case 'invalidCredentials':
              this.error$.next('Credenziali non valide');
              break;
            case 'tooManyAttempts':
              this.error$.next('Troppi tentativi falliti. Attendi qualche minuto.');
              break;
            default:
              this.error$.next('Errore sconosciuto');
          }
          return of(null);
        }),
        tap((user) => {
          this.loading$.next(false);

          if (user !== null) {
            if (user.role === 'checkin') {
              this.router.navigate(['/gate']);
              return;
            }
          }

          this.router.navigate(['/']);

        })
      ))
    );
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
