@if (auth.user$ | async; as user) {
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu utente" >
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div class="px-4 py-1">
      <strong>{{user.firstName | titlecase}} {{user.lastName | titlecase}}</strong><br>
      <span class="mat-caption">{{user.email}}</span>
      <div class="status-badge w-auto">{{user.role}}</div>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="auth.logout$.next()">
      <mat-icon color="warn">logout</mat-icon>
      <span class="!text-warn">Logout</span>
    </button>
  </mat-menu>
}
